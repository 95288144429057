var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.wrapperStyles)},[_c('base-container',{attrs:{"vertical-center":""}},[(_vm.label)?_c('base-typography',{attrs:{"value":_vm.label,"caption":"","single":""}}):_vm._e(),(_vm.$slots.description)?_c('base-popup',{attrs:{"open-hover":"","activator-small":"","activator-color":"default","activator-icon":"fas fa-question-circle"}},[_vm._t("description")],2):_vm._e()],1),_c('base-menu',{attrs:{"no-padding":"","offset-x":"","offset-y":"","disabled":_vm.disabled || _vm.frozen},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('base-primitive-card',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"flat":"","ripple":!_vm.frozen},on:{"dragover":function($event){$event.preventDefault();return _vm.fileDragOver.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.fileDrop.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.fileDragLeave.apply(null, arguments)}}},'base-primitive-card',attrs,false),_vm.hasImage ? on : { click: _vm.editImage }),[(!_vm.ready)?_c('div',{style:({
              width: '100%',
              paddingTop: _vm.aspectPercent,
            })},[_c('base-container',{style:({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }),attrs:{"vertical-center":"","horizontal-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):(_vm.imageUrl !== '')?_c('div',{style:({
              width: '100%',
              paddingTop: _vm.aspectPercent,
              backgroundSize: 'contain',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat no-repeat',
              backgroundImage: ("url('" + _vm.imageUrl + "')"),
            })},[_c('v-scale-transition',[(!_vm.frozen)?_c('div',{style:({
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                width: '3em',
                height: '3em',
                background: _vm.$vuetify.theme.currentTheme.primary,
                'border-radius': '50%',
                transform: 'translate(50%,50%)'
              })},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('base-icon',{attrs:{"icon":"far fa-edit","color":"white"}})],1)],1):_vm._e()])],1):_c('div',{style:({
              position: 'relative',
              border: 'dotted 1px #607D8B',
              borderRadius: '4px',
              width: '100%',
              paddingTop: _vm.aspectPercent,
            })},[_c('div',{style:({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              })},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":"","direction-column":""}},[_vm._t("placeholder",function(){return [_c('base-icon',{attrs:{"large":""}},[_vm._v(" fas fa-image ")]),(!_vm.frozen)?_c('base-typography',{attrs:{"single":"","caption":"","center":""}},[_vm._v(" ドラッグ & ドロップ"),_c('br'),_vm._v(" または"),_c('br'),_vm._v("クリックで画像を選択 ")]):_c('base-typography',{attrs:{"single":"","caption":"","center":""}},[_vm._v(" 画像は"),_c('br'),_vm._v(" 設定されていません ")])]})],2)],1),(!_vm.frozen)?_c('div',{style:({
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                width: '3em',
                height: '3em',
                background: _vm.$vuetify.theme.currentTheme.primary,
                'border-radius': '50%',
                transform: 'translate(50%,50%)'
              })},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('base-icon',{attrs:{"icon":"far fa-edit","color":"white"}})],1)],1):_vm._e()]),_c('image-crop-box',{attrs:{"src":_vm.cropOriginalUrl,"mime-type":_vm.originalMimeType,"aspect-ratio":_vm.aspectRatio,"resize-height":_vm.resizeHeight,"resize-width":_vm.resizeWidth},on:{"crop":_vm.cropped}})],1)]}}]),model:{value:(_vm.showImageMenu),callback:function ($$v) {_vm.showImageMenu=$$v},expression:"showImageMenu"}},[_c('v-list',{attrs:{"width":"300px"}},[_c('v-list-item',{on:{"click":_vm.editImage}},[_c('v-list-item-icon',[_c('base-icon',{attrs:{"icon":"far fa-edit"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 編集 ")])],1)],1),(_vm.hasImage && !_vm.confirmDelete)?_c('v-list-item',{on:{"click":function($event){_vm.confirmDelete = true}}},[_c('v-list-item-icon',[_c('base-icon',{attrs:{"icon":"fas fa-trash"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 削除 ")])],1)],1):_vm._e(),(_vm.confirmDelete)?_c('v-list-item',[_c('v-list-item-content',[_c('div',[_c('base-divider',{attrs:{"no-margin":""}}),_c('base-typography',{staticClass:"mt-2",attrs:{"center":""}},[_vm._v(" 画像を削除してよろしいですか？ ")])],1),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('base-button',{attrs:{"label":"キャンセル","text":"","color":"wont"},on:{"click":function($event){_vm.confirmDelete = false}}}),_c('base-button',{attrs:{"label":"削除","color":"error"},on:{"click":_vm.deleteImage}})],1)])],1):_vm._e()],1)],1),_c('input',{ref:"file",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.fileChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }